import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import svgStyles from "./styles/pages/svg.module.scss"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

export default function customScrollTrigger({ setIsInactive }) {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.defaults({ toggleActions: "play none reverse reset", markers: false })

    const roomTl = gsap.timeline();
    ScrollTrigger.create({
      animation: roomTl,
      trigger: ".text",
      start: 0,
      end: 6000,
      pin: "#room",
      scrub: true,
    })
    roomTl
      .set("#room", {  scale: 3.5, x: -250, y: -4200 })
      .to("#room", { })
      .to("#room", { })
      .to("#room", { })
      .to("#room", { })
      .to("#room", {  scale: 2.5, x: "+=150", y: "-=250" })
      .to("#room", { })
      .to("#room", { })
      .to("#room", {  ease: "power1.out", scale: 0.57, x: "+=120", y: "-=320" })
      .to("#room", { })
      .to("#room", {  scale: 0.4, x: "-=20", y: "-=100" })
      .to("#room", { })
      .to("#room", { })
      .to("#room", { })
      .to("#room", { y: "+=1" })

      // BOTTOM RIGHT THING
    const foregroundTl = gsap.timeline();
    ScrollTrigger.create({
      animation: foregroundTl,
      trigger: ".text",
      start: 0,
      end: 6000,
      pin: "#foreground",
      scrub: true,
      // onLeave: () => setIsInactive(true),
      // onEnterBack: () => setIsInactive(false),
      // toggleClass: {
      //   targets: "#foreground",
      //   className: svgStyles.homeFgActive
      // }
    })
    // front right
    foregroundTl
      .set("#foreground", { scale: 3, x: 3650, y: 5950 })
      .to("#foreground", { })
      .to("#foreground", { })
      .to("#foreground", { })
      .to("#foreground", { })
      .to("#foreground", { })
      .to("#foreground", { })
      .to("#foreground", { })
      .to("#foreground", { scale: 3, x: "-=1830", y: "-=1360" })
      .to("#foreground", { })
      .to("#foreground", { scale: 1.5, x: "-=750", y: "-=780" })
      .to("#foreground", { })
      .to("#foreground", { })
      .to("#foreground", { })
      .to("#foreground", { y: "+=1" })

      // front left
    const midTl = gsap.timeline();
    ScrollTrigger.create({
      animation: midTl,
      trigger: ".text",
      start: 0,
      end: 6000,
      pin: "#mid",
      scrub: true,
    })
    midTl
      .set("#mid", {  scale: 5, x: -1000, y: 1050 })
      .to("#mid", {  })
      .to("#mid", {  })
      .to("#mid", {  })
      .to("#mid", {  })
      .to("#mid", {  })
      .to("#mid", {  })
      .to("#mid", {  })
      .to("#mid", {  scale: 5, x: "+=900", y: "-=900" })
      .to("#mid", {  })
      .to("#mid", {  scale: 2.5, x: "+=370", y: "-=600" })
      .to("#mid", {  })
      .to("#mid", {  })
      .to("#mid", {  })
      .to("#mid", { y: "+=1"  })
    
    const backgroundTl = gsap.timeline();
    ScrollTrigger.create({
      animation: backgroundTl,
      trigger: ".text",
      start: 0,
      end: 6000,
      pin: "#background",
      scrub: true,
    })
    backgroundTl
      .set("#background", { scale: 15, x: 2100, y: -7930 })
      .to("#background", { })
      .to("#background", { })
      .to("#background", { })
      .to("#background", { })
      .to("#background", { scale: 11, x: "-=555", y: "+=600" })
      .to("#background", { })
      .to("#background", { })
      .to("#background", { ease: "power1.out", scale: 2.5, x: "-=1165", y: "+=1300" })
      .to("#background", { })
      .to("#background", { scale: 1.5, x: "-=170", y: "+=50" })
      .to("#background", { })
      .to("#background", { })
      .to("#background", { })
      .to("#background", { y: "+=1" })
    
    const section1TitleTl = gsap.timeline();
    ScrollTrigger.create({
      animation: section1TitleTl,
      trigger: ".text",
      start: 0,
      end: 6000,
      pin: "#background",
      scrub: true,
    })
    section1TitleTl
      .set("#section1Title", { autoAlpha: 1, y: 0 })
      .to("#section1Title", { })
      .to("#section1Title", { })
      .to("#section1Title", { autoAlpha: 0, y: "-=600" })
      .to("#section1Title", { })
      .to("#section1Title", { })
      .to("#section1Title", { })
      .to("#section1Title", { })
      .to("#section1Title", { })
      .to("#section1Title", { })
      .to("#section1Title", { })
      .to("#section1Title", { })
      .to("#section1Title", { })
      .to("#section1Title", { })
      .to("#section1Title", { y: "+=1" })

    const section1ContentTl = gsap.timeline();
    ScrollTrigger.create({
      animation: section1ContentTl,
      trigger: ".text",
      start: 0,
            end: 6000,
      pin: "#background",
      scrub: true,
    })
    section1ContentTl
      .set("#section1Content", { opacity: 0, y: 200 })
      .to("#section1Content", { opacity: 1, y: 0 })
      .to("#section1Content", { })
      .to("#section1Content", { opacity: 0, y: -400 })
      .to("#section1Content", { })
      .to("#section1Content", { })
      .to("#section1Content", { })
      .to("#section1Content", { })
      .to("#section1Content", { })
      .to("#section1Content", { })
      .to("#section1Content", { })
      .to("#section1Content", { })
      .to("#section1Content", { })
      .to("#section1Content", { })
      .to("#section1Content", { y: "+=1" })

    const section2TitleTl = gsap.timeline();
    ScrollTrigger.create({
      animation: section2TitleTl,
      trigger: ".text",
      start: 0,
            end: 6000,
      pin: "#background",
      scrub: true,
    })
    section2TitleTl
      .set("#appTitle", { opacity: 0, y: 300 })
      .to("#appTitle", { })
      .to("#appTitle", { })
      .to("#appTitle", { opacity: 1, y: 0 })
      .to("#appTitle", { })
      .to("#appTitle", { opacity: 0, y: -200 })
      .to("#appTitle", { })
      .to("#appTitle", { })
      .to("#appTitle", { })
      .to("#appTitle", { })
      .to("#appTitle", { })
      .to("#appTitle", { })
      .to("#appTitle", { })
      .to("#appTitle", { })
      .to("#appTitle", { y: "+=1" })

    const section2ContentTl = gsap.timeline();
    ScrollTrigger.create({
      animation: section2ContentTl,
      trigger: ".text",
      start: 0,
            end: 6000,
      pin: "#background",
      scrub: true,
    })
    section2ContentTl
      .set(".appContent", { opacity: 0, y: 300 })
      .to(".appContent", { })
      .to(".appContent", { })
      .to(".appContent", { opacity: 1, y: 0 })
      .to(".appContent", { })
      .to(".appContent", { opacity: 0, y: -200 })
      .to(".appContent", { })
      .to(".appContent", { })
      .to(".appContent", { })
      .to(".appContent", { })
      .to(".appContent", { })
      .to(".appContent", { })
      .to(".appContent", { })
      .to(".appContent", { })
      .to(".appContent", { y: "+=1" })


    const section2Highlight = gsap.timeline();
    ScrollTrigger.create({
      animation: section2Highlight,
      trigger: ".text",
      start: 0,
            end: 6000,
      pin: "#background",
      scrub: true,
    })
   section2Highlight 
      .set("#Layer_2", { opacity: 0 })
      .to("#Layer_2", { })
      .to("#Layer_2", { })
      .to("#Layer_2", { opacity: 1 })
      .to("#Layer_2", { })
      .to("#Layer_2", { opacity: 0 })
      .to("#Layer_2", { })
      .to("#Layer_2", { })
      .to("#Layer_2", { })
      .to("#Layer_2", { })
      .to("#Layer_2", { })
      .to("#Layer_2", { })
      .to("#Layer_2", { })
      .to("#Layer_2", { })
      .to("#Layer_2", { y: "+=1" })

    const section3TitleTl = gsap.timeline();
    ScrollTrigger.create({
      animation: section3TitleTl,
      trigger: ".text",
      start: 0,
            end: 6000,
      pin: "#background",
      scrub: true,
    })
    section3TitleTl
    .set("#webTitle", { opacity: 0, y: 300 })
    .to("#webTitle", { })
    .to("#webTitle", { })
    .to("#webTitle", { })
    .to("#webTitle", { })
    .to("#webTitle", { })
    .to("#webTitle", { opacity: 1, y: 0 })
    .to("#webTitle", { })
    .to("#webTitle", { opacity: 0, y: -200 })
    .to("#webTitle", { })
    .to("#webTitle", { })
    .to("#webTitle", { })
    .to("#webTitle", { })
    .to("#webTitle", { })
    .to("#webTitle", { y: "+=1" })

    const section3ContentTl = gsap.timeline();
    ScrollTrigger.create({
      animation: section3ContentTl,
      trigger: ".text",
      start: 0,
            end: 6000,
      pin: "#background",
      scrub: true,
    })
    section3ContentTl
      .set("#webContent", { duration:1 , opacity: 0, y: 300 })
      .to("#webContent", { duration:1 , })
      .to("#webContent", { duration:1 , })
      .to("#webContent", { duration:1 , })
      .to("#webContent", { duration:1 , })
      .to("#webContent", { duration:1 , })
      .to("#webContent", { duration:1 , opacity: 1, y: 0 })
      .to("#webContent", { duration:1 , })
      .to("#webContent", { duration:1 , opacity: 0, y: -200 })
      .to("#webContent", { duration:1 , })
      .to("#webContent", { duration:1 , })
      .to("#webContent", { duration:1 , })
      .to("#webContent", { duration:1 , })
      .to("#webContent", { duration:1 , })
      .to("#webContent", { duration:1 , y: "+=1" })

    const section3Highlight = gsap.timeline();
    ScrollTrigger.create({
      animation: section3Highlight,
      trigger: ".text",
      start: 0,
            end: 6000,
      pin: "#background",
      scrub: true,
    })
   section3Highlight 
      .set("#Layer_3", { duration: 1 , opacity: 0 })
      .to("#Layer_3", { duration:1 , })
      .to("#Layer_3", { duration:1 , })
      .to("#Layer_3", { duration:1 , })
      .to("#Layer_3", { duration:1 , })
      .to("#Layer_3", { duration:1 , })
      .to("#Layer_3", { duration:1 , opacity: 1 })
      .to("#Layer_3", { duration:1 , })
      .to("#Layer_3", { duration:1 , opacity: 0 })
      .to("#Layer_3", { duration:1 , })
      .to("#Layer_3", { duration:1 , })
      .to("#Layer_3", { duration:1 , })
      .to("#Layer_3", { duration:1 , })
      .to("#Layer_3", { duration:1 , })
      .to("#Layer_3", { duration:1 , y: "+=1" })

    const section4TitleTl = gsap.timeline();
    ScrollTrigger.create({
      animation: section4TitleTl,
      trigger: ".text",
      start: 0,
            end: 6000,
      pin: "#background",
      scrub: true,
    })
    section4TitleTl
    .set("#joinTitle", { opacity: 0, y: 300 })
    .to("#joinTitle", { })
    .to("#joinTitle", { })
    .to("#joinTitle", { })
    .to("#joinTitle", { })
    .to("#joinTitle", { })
    .to("#joinTitle", { })
    .to("#joinTitle", { })
    .to("#joinTitle", { opacity: 1, y: 0 })
    .to("#joinTitle", { })
    .to("#joinTitle", { opacity: 0, y: -200 })
    .to("#joinTitle", { })
    .to("#joinTitle", { })
    .to("#joinTitle", { })
    .to("#joinTitle", { y: "+=1" })

    const section4ContentTl = gsap.timeline();
    ScrollTrigger.create({
      animation: section4ContentTl,
      trigger: ".text",
      start: 0,
            end: 6000,
      pin: "#background",
      scrub: true,
    })
    section4ContentTl
      .set("#joinContent", { opacity: 0, y: 300 })
      .to("#joinContent", { })
      .to("#joinContent", { })
      .to("#joinContent", { })
      .to("#joinContent", { })
      .to("#joinContent", { })
      .to("#joinContent", { })
      .to("#joinContent", { })
      .to("#joinContent", { opacity: 1, y: 0 })
      .to("#joinContent", { })
      .to("#joinContent", { opacity: 0, y: -200 })
      .to("#joinContent", { })
      .to("#joinContent", { })
      .to("#joinContent", { })
      .to("#joinContent", { y: "+=1" })

    const section4Highlight = gsap.timeline();
    ScrollTrigger.create({
      animation: section4Highlight,
      trigger: ".text",
      start: 0,
            end: 6000,
      pin: "#background",
      scrub: true,
    })
    section4Highlight
      .set("#BGLayer_2", { opacity: 0 })
      .to("#BGLayer_2", { })
      .to("#BGLayer_2", { })
      .to("#BGLayer_2", { })
      .to("#BGLayer_2", { })
      .to("#BGLayer_2", { })
      .to("#BGLayer_2", { })
      .to("#BGLayer_2", { })
      .to("#BGLayer_2", { opacity: 1 })
      .to("#BGLayer_2", { })
      .to("#BGLayer_2", { opacity: 0 })
      .to("#BGLayer_2", { })
      .to("#BGLayer_2", { })
      .to("#BGLayer_2", { })
      .to("#BGLayer_2", { y: "+=1" })

    const section5TitleTl = gsap.timeline();
    ScrollTrigger.create({
      animation: section5TitleTl,
      trigger: ".text",
      start: 0,
            end: 6000,
      pin: "#background",
      scrub: true,
    })
    section5TitleTl
    .set("#archiTitle", { opacity: 0, y: 300 })
    .to("#archiTitle", { })
    .to("#archiTitle", { })
    .to("#archiTitle", { })
    .to("#archiTitle", { })
    .to("#archiTitle", { })
    .to("#archiTitle", { })
    .to("#archiTitle", { })
    .to("#archiTitle", { })
    .to("#archiTitle", { })
    .to("#archiTitle", { })
    .to("#archiTitle", { opacity: 1, y: 0 })
    .to("#archiTitle", { })
    .to("#archiTitle", { })
    .to("#archiTitle", { y: "+=1" })

    const section5ContentTl = gsap.timeline();
    ScrollTrigger.create({
      animation: section5ContentTl,
      trigger: ".text",
      start: 0,
            end: 6000,
      pin: "#background",
      scrub: true,
    })
    section5ContentTl
      .set("#archiContent", { opacity: 0, y: 300 })
      .to("#archiContent", { })
      .to("#archiContent", { })
      .to("#archiContent", { })
      .to("#archiContent", { })
      .to("#archiContent", { })
      .to("#archiContent", { })
      .to("#archiContent", { })
      .to("#archiContent", { })
      .to("#archiContent", { })
      .to("#archiContent", { })
      .to("#archiContent", { opacity: 1, y: 0 })
      .to("#archiContent", { })
      .to("#archiContent", { })
      .to("#archiContent", { y: "+=1" })

    const section5Highlight= gsap.timeline();
    ScrollTrigger.create({
      animation: section5Highlight,
      trigger: ".text",
      start: 0,
            end: 6000,
      pin: "#background",
      scrub: true,
    })
    section5Highlight
      .set("#BLDGLayer_3", { opacity: 0 })
      .to("#BLDGLayer_3", { })
      .to("#BLDGLayer_3", { })
      .to("#BLDGLayer_3", { })
      .to("#BLDGLayer_3", { })
      .to("#BLDGLayer_3", { })
      .to("#BLDGLayer_3", { })
      .to("#BLDGLayer_3", { })
      .to("#BLDGLayer_3", { })
      .to("#BLDGLayer_3", { })
      .to("#BLDGLayer_3", { })
      .to("#BLDGLayer_3", { opacity: 1 })
      .to("#BLDGLayer_3", { })
      .to("#BLDGLayer_3", { })
      .to("#BLDGLayer_3", { y: "+=1" })
}