import React from "react"
import { Link } from "gatsby"
import useBlogData from "../static_queries/useBlogData"
import blogListStyles from "../styles/components/bloglist.module.scss"
import Img from 'gatsby-image'
import svgStyles from "../styles/pages/svg.module.scss"

export default function BlogList() {

  const blogData = useBlogData()
  const filtered = blogData
    .filter(blog =>  blog.node.frontmatter.title !== "")
    .filter(b => b.node.frontmatter.tag)
    .filter(b => b.node.frontmatter.tag.length > 0)

  const [intro, web, app, join, archi] = ["intro", "web", "app", "join", "archi"]
    .map(tag => filtered
      .filter(b => b.node.frontmatter.tag.includes(tag))
    )
  
  const renderSection = x => x
    .map(b => {
      return (
      <>
        <h3 className={`${b.node.frontmatter.tag[0]}Content`}>
          <a href={`/blog/${b.node.fields.slug}/`}>
            {b.node.frontmatter.title}
          </a>
        </h3>
        <p className={`${b.node.frontmatter.tag[0]}Content`}>
              {b.node.excerpt}
        </p>
      </>
    )
      }
    )

  const { node: introNode } = intro.shift()

  function renderBlogData() {
    return (
      <>
        <div className={svgStyles.section1}>
          <h2 id="section1Title" className={svgStyles.section1__title}>andy hogan.</h2>
          <div id="section1Content" className={svgStyles.section1__content}>
            <div className={svgStyles.line}></div>
            <h3>{introNode.frontmatter.title}</h3>
            <p>{introNode.excerpt}</p>
          </div>
        </div>
        <div className={svgStyles.section1}>
          <h2 id="appTitle" className={svgStyles.section1__title}>app design</h2>
          <div id="appContent" className={`appContent ${svgStyles.section1__content}`}>
            <div className={svgStyles.line}></div>
            {renderSection(app)}
          </div>
        </div>
        <div className={svgStyles.section1}>
          <h2 id="webTitle" className={svgStyles.section1__title}>web design</h2>
          <div id="webContent" className={svgStyles.section1__content}>
            <div className={svgStyles.line}></div>
            {renderSection(web)}
          </div>
        </div>
        <div className={svgStyles.section1}>
          <h2 id="joinTitle" className={svgStyles.section1__title}>joinery and<br />interior design</h2>
          <div id="joinContent" className={svgStyles.section1__content}>
            <div className={svgStyles.line}></div>
            {renderSection(join)}
          </div>
        </div>
        <div className={svgStyles.section1}>
          <h2 id="archiTitle" className={svgStyles.section1__title}>architectural<br/>design</h2>
          <div id="archiContent" className={svgStyles.section1__content}>
            <div className={svgStyles.line}></div>
            {renderSection(archi)}
          </div>
        </div>
      </>
    )
  }
  return (renderBlogData())
}