import React, { useRef, useEffect } from "react"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import Layout from "../components/Layout"
import BlogList from "../components/BlogList"
import useBlogData from "../static_queries/useBlogData"

import customScrollTrigger from "../customScrollTrigger"
import svgStyles from "../styles/pages/svg.module.scss"

import Room from '../../static/svg/room.inline.svg'
import Foreground from '../../static/svg/foreground.inline.svg'
import Mid from '../../static/svg/mid.inline.svg'
import Background from '../../static/svg/background.inline.svg'

var svgNS = "http://www.w3.org/2000/svg";
var xlinkNS = "http://www.w3.org/1999/xlink";

export default function IndexPage() {
  const bontainerEl = useRef(null);

  // for highlighting, see this:
  // https://projects.invisionapp.com/prototype/cke2r9uzx006g1v01zqw2hf9p/play

  // for the sometimes doens't load issue, see this:
  // https://greensock.com/forums/topic/24528-react-gsap-timeline-dont-start-everytime/

  // for flash of unstyled content
  // https://greensock.com/fouc/
 const [isInactive, setIsInactive] = React.useState(false) 
  
    // bontainerEl.current.preventDefault()
    useEffect(() => {
      customScrollTrigger({ setIsInactive })
  }, [])

    useEffect(() => {
      console.log(document.getElementById("Pooter"))
      if (document.getElementById("Pooter")) {
        var use = document.createElementNS(svgNS, "use");
        // Point it at our pin marker (the circle)
        use.setAttributeNS(xlinkNS, "href", "#pin");
        // Set it's x and y
        use.setAttribute("x", 108);
        use.setAttribute("y", 110);
        console.log(use)
        // Add it to the "markers" group
        document.getElementById("Pooter").appendChild(use); 
      }
    })

  return (
        <div  ref={bontainerEl} className={svgStyles.bontainer}>
          <div className={svgStyles.text}>
            <BlogList />
          </div>
          <main className={svgStyles.main}>
            <div className={svgStyles.svgs}>
              <Foreground id="foreground" alt="Foreground" className={svgStyles.homeFg} />  
              {/* fg is to thhe right of mid, in front of bg */}
              <Mid id="mid" alt="Mid" className={svgStyles.homeMid}/>
               {/* mid is in front of the apartment bg */}
              <Background id="background" alt="Background" className={svgStyles.homeBg} />
               {/* bg is the apartment that room is in */}
              <Room id="room" alt="Room" className={svgStyles.homeRoom} />
              {/* fg is to thhe right of mid, in front of bg */}
            </div>
          </main>
        </div>
  )
}